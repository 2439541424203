import createLucideIcon from '../createLucideIcon';

const HeartHandshake = createLucideIcon('HeartHandshake', [
  [
    'path',
    {
      d: 'M20.42 4.58a5.4 5.4 0 0 0-7.65 0l-.77.78-.77-.78a5.4 5.4 0 0 0-7.65 0C1.46 6.7 1.33 10.28 4 13l8 8 8-8c2.67-2.72 2.54-6.3.42-8.42z',
      key: '3q7jk9',
    },
  ],
  [
    'path',
    {
      d: 'M12 5.36 8.87 8.5a2.13 2.13 0 0 0 0 3h0a2.13 2.13 0 0 0 3 0l2.26-2.21a3 3 0 0 1 4.22 0l2.4 2.4',
      key: 't2xyyg',
    },
  ],
  ['path', { d: 'm18 15-2-2', key: '60u0ii' }],
  ['path', { d: 'm15 18-2-2', key: '6p76be' }],
]);

export default HeartHandshake;
